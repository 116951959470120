<template>
  <div class="review">
    <InfoData></InfoData>
    <TableData></TableData>

    <el-dialog :visible.sync="dialogVisible" class="cre" title="确认信息" width="1280px" append-to-body
      :before-close="handleClose">
      <CreateUser @test="close"></CreateUser>
    </el-dialog>
  </div>
</template>

<script>
import CreateUser from "./components/CreateUser.vue";
import InfoData from "./components/InfoData";
import TableData from "./components/TableData";
export default {
  name: "",
  data() {
    return {
      dialogVisible: true,
    };
  },
  components: {
    InfoData,
    TableData,
    CreateUser,
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang='less'>
.review {
  min-height: 249px;
}
</style>


<style lang="less">
.cre {

  .el-dialog__header {
    background: #5b97f9;
    text-align: center;
    padding-top: 14px;
    padding: 10px 20px;

    span {
      color: white;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  .el-dialog__headerbtn {
    top: 16px;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }

  .el-dialog__body {
    padding: 20px 20px 20px 20px;
  }

  .content {
    margin-bottom: 10px;
  }

  .title_collapse {
    width: 300px;
    margin: 0 400px;
    letter-spacing: 1px;
    background: #5b97f9;
    text-align: center;
    color: white;
    border-radius: 0 0 100px 100px;
  }

  .user_brief {
    span:first-child {
      color: red;
    }

    span:last-child {
      list-style: disc;
      border: 1px solid #979292;
      padding: 3px;
      color: green;
    }
  }
}
</style>